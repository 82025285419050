import React, { FC } from 'react';
import { useLocation } from '@reach/router';
import previousPath from 'utils/previousPath';

import Link from 'components/Link';
import { Wrapper } from './styles';

type Props = {
  className?: string;
};

const BackBtn: FC<Props> = ({ className }) => {
  const { pathname } = useLocation();

  return (
    <Wrapper as={Link} to={previousPath(pathname)} className={className}>
      wróć
    </Wrapper>
  );
};

export default BackBtn;
