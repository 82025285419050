import styled from 'styled-components';
import { SectionSubtitle } from 'components/Common';

export const Title = styled(SectionSubtitle)`
  margin-bottom: 1.4rem;
`;

interface ListProps {
  readonly line: boolean;
}

export const ListWrapper = styled.ul<ListProps>`
  display: flex;
  flex-direction: ${({ line }) => (line ? 'row' : 'column')};
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
`;

interface ItemProps {
  readonly arrow: boolean;
  readonly line: boolean;
}

export const Item = styled.li<ItemProps>`
  display: flex;
  align-items: center;
  margin-right: ${({ line }) => (line ? '7rem' : 0)};
  margin-bottom: 1.7rem;
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.3rem;

  ::before {
    content: '';
    width: 4.5rem;
    height: 3.1rem;
    background: ${({ arrow }) =>
      arrow
        ? "url('/icons/arrowCheck.svg') no-repeat"
        : "url('/icons/checkmarkPrimary.svg') no-repeat"};
  }
`;
