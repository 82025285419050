import styled from 'styled-components';
import { FluidObject } from 'gatsby-image';
import {
  SectionSubtitle,
  SectionWrapper,
  ContentWrapper,
} from 'components/Common';

export const BackWrapper = styled(SectionWrapper)`
  margin: 10rem auto 1rem;
`;

export const Wrapper = styled(SectionWrapper)`
  margin-bottom: 13rem;
`;

export const Content = styled(ContentWrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Column = styled.div`
  flex: 1 0 280px;
  max-width: 480px;
  padding-right: 2rem;
`;

export const Text = styled.div`
  h2,
  h3,
  h4 {
    margin: 0 0 1rem;
    font-size: 1.8rem;
  }

  p {
    margin: 0 0 2rem;
    font-size: 1.3rem;
    line-height: 1.8;
  }

  ul {
    margin: 0 0 2rem;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 2.2rem;
      color: ${({ theme }) => theme.color.dark};
      font-size: 1.3rem;
      line-height: 2.4rem;

      ::before {
        content: '';
        min-width: 4.65rem;
        height: 3.1rem;
        background: url('/icons/checkmarkPrimary.svg') no-repeat;
      }
    }
  }
`;

export const CoverWrapper = styled.div`
  flex: 1 0 250px;
  max-width: 540px;
  margin: 0 auto;

  ${({ theme }) => theme.mq.mobile} {
    margin-bottom: 7.5rem;
    transform: translateY(7.5rem);
  }
`;

interface CoverProps {
  fluid: FluidObject | FluidObject[];
}

export const Cover = styled.img<CoverProps>`
  min-height: 360px;
  border-radius: 142px 15px;
`;

export const StyledList = styled.div`
  margin-bottom: 3.5rem;
`;

export const Subtitle = styled(SectionSubtitle)`
  margin: 6rem 0 3.5rem;
  text-align: center;
`;
