import React, { FC } from 'react';

import { Title, ListWrapper, Item } from './styles';

type Props = {
  title: string;
  items: string[];
  arrow?: boolean;
  line?: boolean;
  className?: string;
};

const List: FC<Props> = ({
  title,
  items,
  arrow = false,
  line = false,
  className,
}) => (
  <div className={className}>
    <Title>{title}</Title>
    <ListWrapper line={line}>
      {items.map((name) => (
        <Item key={name} arrow={arrow} line={line}>
          {name}
        </Item>
      ))}
    </ListWrapper>
  </div>
);

export default List;
