import styled from 'styled-components';

export const Wrapper = styled.a`
  position: relative;
  padding-left: 4rem;
  transition: color 0.15s ease-in-out;
  outline: none;
  color: #9ca2a8;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.45;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  ::before,
  ::after {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 31px;
    height: 31px;
    transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out;
  }

  ::before {
    opacity: 1;
    background: url('/icons/back.svg') no-repeat;
  }

  ::after {
    opacity: 0;
    background: url('/icons/backActive.svg') no-repeat;
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.color.primary};

    ::before {
      opacity: 0;
    }

    ::after {
      opacity: 1;
    }

    ::before,
    ::after {
      transform: translateX(-10px);
    }
  }
`;

export default Wrapper;
