import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import Head from 'views/Head';
import BackBtn from 'components/Button/Back';
import List from 'components/List';
import { SectionTitle, SectionSubtitle } from 'components/Common';
import {
  BackWrapper,
  Wrapper,
  Content,
  Column,
  StyledList,
  CoverWrapper,
  Cover,
  Text,
} from './styles';

const OfferTemplate: FC<OfferQuery> = ({ data }) => {
  const { title, cover, additional } = data.markdownRemark.frontmatter;
  const { html: content } = data.markdownRemark;

  return (
    <>
      <Head title={title} />
      <BackWrapper>
        <BackBtn />
      </BackWrapper>
      <Wrapper>
        <Content>
          <Column>
            <SectionTitle as="h1">{title}</SectionTitle>
            <Text dangerouslySetInnerHTML={{ __html: content }} />
          </Column>
          <CoverWrapper>
            <Cover
              as={Img}
              fluid={cover.childImageSharp.fluid}
              draggable={false}
            />
          </CoverWrapper>
        </Content>
        <Content>
          {!!additional && (
            <StyledList
              as={List}
              title="Możesz połączyć:"
              items={additional}
              line
              arrow
            />
          )}
          <SectionSubtitle>
            Nie musisz przyjeżdżać do Warszawy! Wszystko, czego potrzebujemy
            ustalimy telefonicznie i mailowo.
          </SectionSubtitle>
        </Content>
      </Wrapper>
    </>
  );
};

interface OfferQuery {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        type: string;
        title: string;
        additional: string[];
        cover: {
          publicURL: string;
          childImageSharp: {
            fluid: FluidObject | FluidObject[];
          };
        };
      };
    };
  };
}

export const query = graphql`
  query OfferTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        type
        title
        additional
        cover {
          publicURL
          childImageSharp {
            fluid(quality: 60, maxWidth: 540, maxHeight: 360) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default OfferTemplate;
